<template>
  <section class="section section_votes">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="title title_dark title_second title_svg">Опросы
            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_226_668)">
                <circle cx="19" cy="18" r="18" transform="rotate(-90 19 18)" fill="#59B980"/>
              </g>
              <path
                  d="M15.779 27.0592L16.0039 27.1096L16.1883 26.9713L24.3651 20.8412L24.3659 20.8406C25.6264 19.8918 26.3574 18.7758 26.3574 17.5713C26.3574 16.3668 25.6264 15.2508 24.3659 14.302L24.3651 14.3014L16.1883 8.17123L16.0039 8.03298L15.779 8.0834L15.4623 8.15441L14.4039 8.3917L15.2718 9.04235L23.4482 15.1723C24.5481 15.9984 24.9975 16.8291 24.9975 17.5713C24.9975 18.3135 24.5482 19.1441 23.4484 19.9702C23.4483 19.9702 23.4482 19.9703 23.4482 19.9703L15.2718 26.1002L14.4039 26.7509L15.4623 26.9882L15.779 27.0592Z"
                  fill="white" stroke="white"/>
              <defs>
                <filter id="filter0_d_226_668" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                 result="hardAlpha"/>
                  <feOffset dy="1"/>
                  <feGaussianBlur stdDeviation="0.5"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.34902 0 0 0 0 0.72549 0 0 0 0 0.501961 0 0 0 0.77 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_226_668"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_226_668" result="shape"/>
                </filter>
              </defs>
            </svg>

          </h2>
        </div>
      </div>
      <div class="row pt-4 d-flex align-items-stretch">
        <div class="col-12 col-sm-6 col-md-4" v-for="vote in votes" :key="vote.id">
          <vote-item :vote="vote"></vote-item>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VoteItem from "../components/components/VoteItem";
export default {
  name: "Votes",
  components: {VoteItem},
  data() {
    return {
      votes: []
    }
  },
  methods: {
    loadVotes() {
      this.$axios.get('votes')
        .then(resp => {
          this.votes = resp.data
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mounted() {
    this.loadVotes()
  }
}
</script>

<style scoped lang="scss">
  .section_votes {
    padding: rem(30) 0;
  }
  .votes {
    height: 100%;
    padding: rem(20);
    border-radius: 5px;
    position: relative;
    border: 1px solid rgba(#000 ,.3);
    margin-bottom: rem(30);
    &_disabled {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(grey, .4);
      }
    }
    &__image {
      max-width: 100%;
      vertical-align: top;
    }
    &__link {
      a {
        text-decoration: none;
        color: #000;
        font-size: rem(14);
        svg {
          margin-left: rem(10);
          max-width: 30px;
          height: auto;
        }
      }
    }
  }
</style>